import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StringParseUtilities } from '@utilities/string-parse.utilities';

@Injectable()
export class LegacySearchRedirectGuard implements CanActivate {
  constructor(
    private router: Router,
    private stringParseUtilities: StringParseUtilities
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    const legacySearchRoute =
      /^\/search\/(search_specialties|name|procedures|hospital_affiliations|group_affiliations)\/.+\/\d+\/.+/;
    const url = state.url;

    if (legacySearchRoute.test(url)) {
      const filterParams = this.stringParseUtilities.jsonParse(
        route.url.pop().path
      );
      const pageNumber = route.url.pop().path;
      const searchTypeId = route.url.pop().path;
      const searchType = route.url.pop().path;
      const updatedParams = {
        page: pageNumber,
        ...filterParams,
        ...route.queryParams,
      };

      const urlTree = this.router.createUrlTree(
        ['search', searchType, searchTypeId],
        { queryParams: updatedParams }
      );
      return of(urlTree);
    } else {
      return of(true);
    }
  }
}
