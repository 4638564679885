import { SortConfig } from '@interfaces/sort-config.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SearchSortOption } from '@interfaces/search-sort-option.model';
import searchTypeMap from '@utilities/search-type-map.utilities';

export const selectSortFiltersState =
  createFeatureSelector<SortConfig>('sort_filters');

export const selectSortFilters = createSelector(
  selectSortFiltersState,
  (state: SortConfig) => {
    return state;
  }
);

export const selectSearchTypeSortOptions = (searchType: string) =>
  createSelector(selectSortFiltersState, (state: SortConfig) => {
    if (!state['loading']) {
      return state[searchType] as SearchSortOption[];
    }
  });

export const selectSearchTypeSortDefaultOption = (searchType: string) =>
  createSelector(selectSortFiltersState, (state: SortConfig) => {
    if (!state['loading']) {
      const mappedType = searchTypeMap[searchType] || searchType;
      const defaultOptionBySearchType = state[mappedType + '_default'];
      if (defaultOptionBySearchType) {
        const sort_option = state.default.find(
          (option) => option.translation === defaultOptionBySearchType
        );
        if (sort_option?.query) {
          return sort_option;
        }
      }
      return state.default[0];
    }
  });
