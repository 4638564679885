<zelis-button
  variant="flat"
  class="chat chat-button border-chat-color livechat_button"
  [fullWidth]="true"
  data-cy="chat-button.button"
  color="accent"
  fxLayout="column"
  fxLayoutAlign="start center"
  [attr.id]="'livechat_button_' + chatId"
  [disabled]="(isAvailable | async) === false"
  (clickFn)="startChat()"
>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
    *ngIf="chatRequested | async"
  >
    <span>
      <zelis-progress-spinner
        [diameter]="28"
        [strokeWidth]="2"
      ></zelis-progress-spinner>
    </span>
    <span>{{ 'pat_chat_requesting_chat' | translate }}</span>
  </div>
  <ng-container *ngIf="(chatRequested | async) === false">
    <i
      class="icon-comments-2 mr-2"
      *ngIf="(chatRequested | async) === false"
    ></i>
    <span>{{ buttonCopy }}</span>
  </ng-container>
</zelis-button>
